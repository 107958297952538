/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const subscribe2channel = /* GraphQL */ `
  subscription Subscribe2channel($name: String!) {
    subscribe2channel(name: $name) {
      data
      name
    }
  }
`;
