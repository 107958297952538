import React from "react";
import styled from "styled-components";

const TranscriptBodyDiv = styled.div`
  margin: 0 auto;
  overflow: scroll;
  height: 95%;
`;

const TranscriptBody = ({ data }) => {
  const scrollToBottom = () => {
    const chat = document.getElementById("chatList");
    if (chat) {
      chat.scrollTop = chat.scrollHeight;
    }
  };

  const renderMessage = (segment) => {
    let messageContent;
    if (segment.Sentiment === "NEUTRAL") {
      messageContent = <p style={{ color: "blue" }}>{segment.PartialContent}</p>;
    } else if (segment.Sentiment === "POSITIVE") {
      messageContent = <p style={{ color: "green" }}>{segment.PartialContent}</p>;
    } else if (segment.Sentiment === "NEGATIVE") {
      messageContent = <p style={{ color: "red" }}>{segment.PartialContent}</p>;
    } else {
      messageContent = <p style={{ color: "slategrey" }}>{segment.PartialContent}</p>;
    }
    scrollToBottom();
    return messageContent;
  };

  return (
    <>
      <TranscriptBodyDiv id="chatList">
        <ul style={{ paddingLeft: "10px", marginBottom: "20px" }}>
          {data.map((segment) => (
            <div key={segment.key}>
              <p>{segment.ParticipantRole}</p>
              {renderMessage(segment)}
            </div>
          ))}
        </ul>
      </TranscriptBodyDiv>
    </>
  );
};

export default TranscriptBody;
