import React, { useRef, useEffect } from "react";
import { select, line, curveCardinal } from "d3";

const LineChart = ({ data }) => {
  const svgRef = useRef(null);

  useEffect(() => {
    if (data?.length > 0) {
      const svg = select(svgRef.current);

      svg.selectAll("*").remove();

      const myLine = line()
        .x((value, index) => index * 50)
        .y((value) => 125 - value)
        .curve(curveCardinal);

      svg
        .selectAll("path")
        .data([data])
        .enter()
        .append("path")
        .attr("d", (value) => myLine(value))
        .attr("fill", "none")
        .attr("stroke", "black");
    }
  }, [data]);

  return (
    <>
      <h2>Sentiment Chart</h2>
      <svg ref={svgRef}></svg>
    </>
  );
};

export default LineChart;
