// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useCallback, useEffect, useState } from "react";
import Grid from "aws-northstar/layouts/Grid";
import Inline from "aws-northstar/layouts/Inline";
import Stack from 'aws-northstar/layouts/Stack';

import ConnectCCP from "./ConnectCCP";
import Transcript from "./Transcript";
import CategoryBadges from "./charts/CategoryBadges";
import LineChart from "./charts/LineChart";

const Dashboard = () => {
  // Avoid re-rendering ConnectCCP
  const CCP = useCallback(() => <ConnectCCP />, []);

  const [utterances, setUtterances] = useState([]);
  const [transcripts, setTranscripts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    let sentimentGraphArr = [];
    // Graphs sentiment for both customer and agent
    // Optional: filter on customer only
    transcripts.forEach((segment) => {
      const sentimentScoreMap = {
        NEUTRAL: 50,
        MIXED: 50,
        POSITIVE: 100,
        NEGATIVE: 0,
      };
      let score = sentimentScoreMap[segment.Sentiment];
      sentimentGraphArr.push(score);
    });

    // Only plot the last 7 values based on the width of the graph
    let dataToPlot = sentimentGraphArr.slice(-7);
    setData(dataToPlot);
  }, [transcripts]);

  return (
    <Inline>
      <CCP />
      <Stack>
        <Inline>
          <CategoryBadges categories={categories}></CategoryBadges>
          <LineChart data={data} />
        </Inline>
        <Grid item xs={12}>
          <Transcript
            setUtterances={setUtterances}
            setTranscripts={setTranscripts}
            setCategories={setCategories}
            utterances={utterances}
            transcripts={transcripts}
          />
        </Grid>
      </Stack>
    </Inline>
  );
};

export default Dashboard;
