// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import "amazon-connect-streams";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { useConnected, useDestroy } from "../hooks";
import "../App.css";
import TranscriptBody from "./TranscriptBody";

import Amplify, { API, graphqlOperation } from "aws-amplify";
import * as subscriptions from "../graphql/subscriptions";
import { GRAPHQL_URL,AWS_APPSYNC_REGION } from "../lib";

// AppSync endpoint settings
const myAppConfig = {
  aws_appsync_graphqlEndpoint: GRAPHQL_URL,
  aws_appsync_region: AWS_APPSYNC_REGION,
  aws_appsync_authenticationType: "AWS_LAMBDA",
};


Amplify.configure(myAppConfig);

let subscription = "";

const TranscriptDiv = styled.div`
  width: 700px;
  height: 500px;
  overflow: hidden;
`;

const Transcript = ({
  setUtterances,
  setTranscripts,
  setCategories,
  utterances,
  transcripts,
}) => {
  const [received, setReceived] = useState("");

  // Define the channel name here
  let channel = "";

  const onConnected = useCallback(
    (c) => {
      try {
        const attr = c.getAttributes();

        if (attr === null) {
          throw new Error("attr was null");
        }

        let contactId = c.contactId;
        let token = attr.token.value;
      

        subscription =  API.graphql(
          {
            query : subscriptions.subscribe2channel,
            variables: { name: contactId},
            authToken: contactId+ '+' +token
          }
          ).subscribe({
          next: ({ provider, value }) => {
            setReceived(value.data.subscribe2channel.data);
          },
          error: (error) => console.warn(error),
        });
      } catch (e) {}
    },
    [channel, subscription]
  );
  useConnected(onConnected);

  const onDestroy = useCallback(() => {
    setUtterances([]);
    setTranscripts([]);
    setCategories([]);
  }, [setCategories, setTranscripts, setUtterances]);
  useDestroy(onDestroy);

  useEffect(() => {
    // Subscribe via WebSockets
    if (received) {
      let received_data = JSON.parse(received);
      console.info("*** Received data");
      console.info(received_data);
      if (received_data.EventType === "SEGMENTS") {
        // Handle utterances, set to state
        if (received_data.Segments[0].Utterance != null) {
          received_data.Segments.forEach((segment) => {
            console.info(segment);
            if (segment.Utterance) {
              setUtterances((utterance) => [
                ...utterance,
                {
                  ...segment.Utterance,
                  Sentiment: "NA",
                  Key: utterances.length,
                },
              ]);
            }
          });
        }

        // Handle transcripts
        if (received_data.Segments[0].Transcript != null) {
          received_data.Segments.forEach((segment) => {
            if (segment.Transcript) {
              // Set transcript to state
              setTranscripts((transcript) => [
                ...transcript,
                {
                  ...segment.Transcript,
                  Key: transcripts.length,
                },
              ]);

              // console.info("segment val");
              // console.info(segment.Transcript);

              // Match with utterance based on begin and offset and append property "Sentiment" to matched utterance
              for (let i = 0; i < utterances.length; i++) {
                // console.info(`utterance index ${i}`);
                // console.info("utterance val");
                // console.info(utterances[i]);
                if (
                  utterances[i].BeginOffsetMillis >=
                    segment.Transcript.BeginOffsetMillis ||
                  utterances[i].BeginOffsetMillis <=
                    segment.Transcript.EndOffsetMillis
                ) {
                  // console.info("Found a match between utterance and transcript")
                  setUtterances(
                    utterances.map((utterance) => {
                      if (utterance.Key !== i) return utterance;
                      return {
                        ...utterance,
                        Sentiment: segment.Transcript.Sentiment,
                      };
                    })
                  );
                }
              }
            }
          });
        }

        // Handle categories, set to state
        if (received_data.Segments[0].Categories != null) {
          // console.info("Found a category");
          setCategories((categories) => [
            ...categories,
            ...received_data.Segments[0].Categories.MatchedCategories,
          ]);
        }
      }
    }
  }, [received]);

  return (
    <TranscriptDiv>
      <h2>Transcript</h2>
      {utterances && utterances.length > 0 ? (
        <TranscriptBody data={utterances} />
      ) : (
        <div style={{ margin: "5px" }}>No transcript available.</div>
      )}
    </TranscriptDiv>
  );
};

export default Transcript;
